<template>
  <div class="product-service">
    <BreadCrumb class="top-left" />

    <!-- Banner -->
    <div class="product-service__banner">
      <div class="product-service__banner__text do-flex-col">
        <div class="product-service__banner__text__title">
          Bebas Pilih Pengiriman
        </div>
        <div class="product-service__banner__text__desc">
          <span>Sesuai Kebutuhan</span>
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBanner"
        alt="lionparcel_banner.png"
        @error="(e) => setImageDefault(e, 'product-banner')"
      />
    </div>
    <!-- Banner -->

    <h1 class="product-service__title">
      Jenis Pengiriman Kami
    </h1>

    <h2 class="product-service__subtitle">
      Tersedia berbagai jenis pengiriman yang didukung oleh armada pesawat dan frekuensi
      penerbangan Lion Group
    </h2>

    <!-- Product List -->
    <div
      v-if="!isLoadingToFetch"
      class="flex-index"
    >
      <div
        v-for="(product, index) in products"
        :key="index"
        class="do-flex"
      >
        <ProductList :product="product" />
      </div>
    </div>
    <!-- Product List -->
  </div>
</template>

<script>
import ProductUseCase from './app/usecase/product.js';
import ProductList from './components/ProductCard';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import mixinMobile from '../../misc/mixinMobile';
import { setImageDefault } from '@/components/ImageLazy/util';
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'Product',
  components: { ProductList, BreadCrumb, ImageLazy },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      searchMetaInfo: '_product', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      isLoadingFetchData: false,
      products: [],
      setImageDefault,
    };
  },
  computed: {
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
    imgSrcBanner() {
      return this.windowWidth <= 599 ? '/images/product/banner_mobile.webp' : '/images/product/banner.webp';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.fetchProductList();
  },
  methods: {
    async fetchProductList() {
      this.isLoadingFetchData = true;
      const data = await ProductUseCase.getAll();
      if (data && data.err === null) {
        this.isLoadingFetchData = false;
        this.products = data.data;
      } else {
        this.isLoadingFetchData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
