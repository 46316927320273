import InternalAPI from '../../../../app/baseAxios.js';
import '../types';


/** @borrows InternalAPI */
export default class ProductDetailRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v1/product_detail/data';
  }

  /**
     *
     * @param productID {number}
     * @return {Promise<{data: ProductDetail, err: Error}>}
     */
  async getDetail(productID) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}/${productID}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response) };
    }
  }
}
