<template>
  <div
    class="product-card"
    @click="goToDetail()"
  >
    <div class="product-card__container-ribbon">
      <div
        v-if="product.show_ribbon"
        class="product-card__ribbon"
      >
        <div
          v-if="product.text_ribbon !== '' && product.icon_ribbon_asset == null"
          class="product-card__ribbon__text"
          :style="setStylePromoRibbon()"
        >
          <p>{{ product.text_ribbon }}</p>
        </div>
        <div
          v-else
          class="product-card__ribbon__img"
          :style="setStylePromoRibbon()"
        >
          <img
            :src="product.icon_ribbon_asset"
            :alt="product.slug + '_ribbon.png'"
          >
        </div>
      </div>
    </div>
    <div class="container-tooltiptext">
      <div class="tooltiptext">
        {{ product.text_tooltip }}
      </div>
    </div>
    <div class="do-flex-product-card">
      <div
        class="product-card__estimated-up-to"
        :style="setStyleEstimatedUpTo()"
      >
        {{ product.text_estimation }}
      </div>
    </div>
    <div class="product-card__content">
      <ImageLazy
        :src="product.root_service_asset"
        :alt="product.alt_root_service_asset"
      />
      <div class="product-card__content__product-service">
        <div class="product-card__content__product-service__name">
          {{ product.service_name }}
          <img
            class="bubble-icon"
            :src="'/images/tariff/info.svg'"
            alt="bubble_question.png"
          >
        </div>
        <div class="product-card__content__product-service__title">
          {{ product.text_service_title }}
        </div>
        <div class="product-card__content__product-service__description">
          {{ product.text_service_description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'ProductList',
  components: { ImageLazy },
  props: {
    product: {
      type: Object || Array,
      default() {
        return {
          product: {
            show_ribbon: null,
            text_ribbon: null,
            icon_ribbon_asset: null,
            slug: null,
            text_estimation: null,
            root_service_asset: null,
            alt_root_service_asset: '',
            service_name: null,
            text_service_title: null,
            text_service_description: null,
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    goToDetail() {
      this.$router.push(`/product/${this.product.slug.toLowerCase()}`);
    },
    setStyleEstimatedUpTo() {
      return {
        backgroundColor: this.product.background_color_text_estimation,
        color: this.product.text_color_estimation,
      };
    },
    setStylePromoRibbon() {
      return {
        backgroundColor: this.product.background_color_ribbon,
        color: this.product.text_color_ribbon,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

.product-card:hover .tooltiptext {
  visibility: visible;
}

.product-card {
  width: 100%;
  cursor: pointer;

  .do-flex-product-card {
    display: flex;
    justify-content: space-between;
  }

  &__estimated-up-to {
    border-radius: 12px 0px 4px;
    border-top-left-radius: 12px;
    border-top: 12px;
    display: inline;
    color: #ffffff;
    padding: 1px 20px 1px 20px;
  }

  .container-tooltiptext {
    display: flex;
    justify-content: flex-end;
  }

  .tooltiptext {
    visibility: hidden;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 4px #1a142124;
    font-size: 12px;

    /* Position the tooltip */
    position: absolute;
    margin-top: -15px;
    margin-right: 25px;
    margin-left: -25px;
  }

  &__container-ribbon {
    display: flex;
    justify-content: flex-end;
  }

  &__ribbon {
    /* positioning */
    position: absolute;
    width: 72px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &__img,
    &__text {
      position: absolute;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      /* top left of the page */
      height: 20px;
      width: 130px;
      margin-left: 20px;
      margin-bottom: 37px;
      left: -39px;
      bottom: 21.5px;
      -webkit-transform: rotate(-315deg);
      -moz-transform: rotate(-315deg);
      -ms-transform: rotate(-315deg);
      transform: rotate(-315deg);
      font-size: 12px;
      line-height: 18px;

      /* webkit antialias fix */
      -webkit-backface-visibility: hidden;
    }

    img {
      margin-right: 8px;
      -webkit-transform: rotate(-45deg) scale(1.4);
      -moz-transform: rotate(-45deg) scale(1.4);
      -ms-transform: rotate(-45deg) scale(1.4);
      transform: rotate(-45deg) scale(1.4);
    }

    p {
      margin-right: 8px;
    }
  }

  &__content {
    display: flex;
    gap: 32px;

    @include for-size(mobile) {
      display: block;
      padding: 10px;
    }

    > div:first-child {
      width: 124px;
      min-width: 124px;

      > ::v-deep img {
        margin-left: 20px;
        height: 147px;

        @include for-size(mobile) {
          margin-top: 20px;
          width: 84px;
        }
      }
    }


    &__product-service {
      margin-right: 26px;
      color: #4d4d4d;

      @include for-size(mobile) {
        margin: 10px;
      }

      &__name {
        font-weight: bold;
        line-height: 30px;
        font-size: 20px;
        margin-top: 14px;

        .bubble-icon {
          margin: 1px;
          padding: 0;
          width: 12px;
          height: 12px;
        }
      }

      &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;

        @include for-size(mobile) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      &__description {
        font-size: 14px;
        line-height: 21px;
        margin-top: 20px;
        margin-bottom: 20px;

        @include for-size(mobile) {
          font-size: 12px;
          line-height: 18px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
