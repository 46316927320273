<template>
  <div class="product-detail-card">
    <div class="do-flex">
      <ImageLazy
        :src="product.root_service_asset"
        :alt="product.alt_root_service_asset"
      />
      <div class="product-detail-card__service">
        <p class="product-detail-card__service__name">
          {{ product.service_name }}
        </p>
        <p class="product-detail-card__service__title">
          {{ product.text_service_title }}
        </p>
        <div
          class="product-detail-card__service__estimation"
          :style="{
            backgroundColor: product.background_color_text_estimation,
            color: product.text_color_estimation,
          }"
        >
          {{ product.text_estimation }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageLazy from '@/components/ImageLazy/index.vue';

export default {
  name: 'ProductDetailList',
  components: { ImageLazy },
  props: {
    product: {},
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style.scss";

.product-detail-card {
  height: 100%;
  cursor: pointer;

  .do-flex {
    display: flex;
    flex-direction: row;
    box-shadow: none;
    padding: 0px 9px;

    @include for-size(mobile) {
      padding: 16px 0px;
      margin: 0 25px;
    }

    > div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;

      >::v-deep img {
        margin-right: 21px;
        width: 80px;

        @include for-size(mobile) {
          width: 77px;
        }
      }
    }
  }

  &__service {
    div {
      display: inline-block;
    }

    &__name {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
    }

    &__title {
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
    }

    &__estimation {
      margin-top: 4px;
      border-radius: 6px;
      text-align: center;
      padding: 0.65px 13.05px;
    }
  }
}
</style>
