<template>
  <div 
    v-if="PRODUCT && PRODUCT.status === 'ACTIVE'"  
    class="product-detail"
  >
    <BreadCrumb class="top-left" />

    <div class="do-flex">
      <!-- Info Product -->
      <div class="product-detail__info">
        <ImageLazy :src="PRODUCT.main_asset" :alt="PRODUCT.alt_main_asset" />
        <div class="product-detail__info__title-description">
          <p>Deskripsi</p>
          {{ PRODUCT.service_description }}
        </div>
        <div class="product-detail__info__title-terms-conditions">
          <p>
            Syarat dan Ketentuan Umum Pengiriman
            {{ PRODUCT.service_name }} :
          </p>
        </div>
        <div
          v-if="PRODUCT.terms_conditions !== ''"
          class="product-detail__info__content-terms-conditions"
          v-html="PRODUCT.terms_conditions"
        />
        <div class="wrapper-button">
          <Button
            text="Kirim Sekarang"
            class="custom-btn"
            type-class="red"
            :custom-padding="true"
            @click="toggleDownloadModal"
          />
          <Button
            text="Cek Agen Terdekat"
            class="custom-btn"
            type-class="outline-red"
            :custom-padding="true"
            @click="goToAgentLocation"
          />
        </div>
      </div>
      <!-- Info Product -->

      <!-- Product List -->
      <div v-if="!isLoadingToFetch" class="product-detail__list">
        <div class="bold-text">
          Jenis pengiriman lainnya
        </div>
        <hr />
        <div v-for="(product, index) in products" :key="index">
          <div class="other-product-list" @click="otherProduct(product.slug)">
            <ProductDetailList
              v-if="product.slug != PRODUCT.slug"
              :product="product"
            />
          </div>
          <hr v-if="product.slug != PRODUCT.slug" />
        </div>
      </div>
      <!-- Product List -->
    </div>
    <!-- Modal -->
    <ModalDownload
      v-if="isModalDownloadOpen"
      @close="toggleDownloadModal"
    />
    <!-- Modal -->
  </div>
  <div v-else-if="!PRODUCT || PRODUCT.status !== 'ACTIVE' && !isLoadingToFetch">
    <NotFoundPage />
  </div>
</template>

<script>
import ProductUseCase from '../app/usecase/product.js';
import ProductDetailUseCase from '../app/usecase/productDetail.js';
import ProductDetailList from '../components/ProductDetailCard';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import mixinMobile from '../../../misc/mixinMobile';
import ImageLazy from '@/components/ImageLazy/index.vue';
import defaultSso from '@/helpers/SEODefault';
import { mapGetters } from 'vuex';
import Button from "@/components/new-button/Button.vue";
import ModalDownload from "../../new-check-price/components/ModalDownload.vue";
import { getStoreUrlByOS, isAndroid, isIOS } from '@/pages/new-merchant/app/utils/getOs';
import NotFoundPage from '@/pages/not-found/NotFound.vue';

export default {
  name: 'ProductDetail',
  components: { ProductDetailList, BreadCrumb, ImageLazy, Button, ModalDownload, NotFoundPage, },
  mixins: [mixinMobile],
  metaInfo() {
    if (JSON.stringify(this.PRODUCT) === '{}') {
      return this.SEO({
        channel: 'article',
        ...defaultSso,
      });
    }
    return this.SEO({
      channel: 'article',
      title: this.PRODUCT.service_name,
      description:
        this.PRODUCT.service_name + ' ' + this.PRODUCT.service_description,
      keywords:
        (this.PRODUCT.service_name ? this.PRODUCT.service_name : '')
          .toLowerCase()
          .split(' & ')
          .join(' ')
          .split(', ')
          .join(' ')
          .split(' ')
          .join(', ') +
        ', ' +
        (this.PRODUCT.service_description
          ? this.PRODUCT.service_description
          : ''
        )
          .toLowerCase()
          .split(' & ')
          .join(' ')
          .split(', ')
          .join(' ')
          .split(' ')
          .join(', '),
      publishedTime: this.PRODUCT.created_at
        ? `${new Date(this.PRODUCT.created_at).toISOString()}T+07:00`
        : `${new Date().toISOString()}T+07:00`,
      image: this.PRODUCT.main_asset,
    });
  },
  data() {
    return {
      isLoadingFetchData: false,
      products: [],
      isModalDownloadOpen: false,
    };
  },
  async asyncData({ route, store }) {
    const data = await ProductDetailUseCase.getDetail(
      route.params.product_name,
    );
    if (data && data.err === null) {
      store.dispatch('setProductDetail', data.data);
    }
  },
  computed: {
    ...mapGetters(['PRODUCT']),
    isLoadingToFetch() {
      return this.isLoadingFetchData;
    },
  },
  watch: {
    '$route.path': {
      handler() {
        this.fetchProductDetail();
        this.fetchProductList();
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('setProductDetail', {});
    window.scrollTo(0, 1);
  },
  async created() {
    await this.fetchProductDetail();
    await this.fetchProductList();
  },
  methods: {
    async fetchProductDetail() {
      this.isLoadingFetchData = true;
      const data = await ProductDetailUseCase.getDetail(
        this.$route.params.product_name,
      );
      if (data && data.err === null) {
        this.isLoadingFetchData = false;
        if(data.data.terms_conditions.match('instagram-media')){
          let instagramScript = document.createElement('script')
          instagramScript.setAttribute('async', '')
          instagramScript.setAttribute('src', '//www.instagram.com/embed.js')
          document.head.appendChild(instagramScript)
        }
        this.$store.dispatch('setProductDetail', data.data);
      } else {
        this.isLoadingFetchData = false;
      }
    },
    async fetchProductList() {
      this.isLoadingFetchData = true;
      const data = await ProductUseCase.getAll();
      if (data && data.err == null) {
        this.isLoadingFetchData = false;
        this.products = data.data;
      } else {
        this.isLoadingFetchData = false;
      }
    },
    otherProduct(slug) {
      this.$router.push(`/product/${slug}`);
      this.fetchProductDetail();
      window.scrollTo(0, 0);
    },
    goToAgentLocation() {
      this.$router.push("/lokasi-agen");
      window.scrollTo(0, 0);
    },
    toggleDownloadModal() {
      if (this.isModalDownloadOpen === false && (isAndroid() || isIOS())) {
        return this.redirectToApp()
      }
      setTimeout(() => {
        this.isModalDownloadOpen = !this.isModalDownloadOpen
      }, 50)
    },
    redirectToApp() {
      let intervalId = window.setTimeout(() => {
        let storeUrl = getStoreUrlByOS()
        window.location = storeUrl;
      }, 500)
      window.location = `lionparcel://app/`;
      window.addEventListener('blur', () => {
        clearTimeout(intervalId)
      })
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          clearTimeout(intervalId)
        }
      }, false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
