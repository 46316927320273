import InternalAPI from '../../../../app/baseAxios.js';
import '../types';


/** @borrows InternalAPI */
export default class ProductRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v2/product_detail/data';
  }

  /**
     *
     * @param page {number}
     * @param perPage {number}
     * @param status {string}
     * @return {Promise<{data: Product[], err: Error}>}
     */
  async getAll(page = 1, perPage = 15, status = 'ACTIVE') {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}?page=${page}&per_page=${perPage}&status=${status}&q=`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response) };
    }
  }
}
