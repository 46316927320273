import ProductRepository from '../api/product.js';
import '../types';

class ProductUseCase {
  constructor() {
    /** @type {ProductRepository} */
    this.api = new ProductRepository();
  }

  /**
    *
    * @param page {number}
    * @param perPage {number}
    * @param status {string}
    * @return {Promise<{data: Product[], err: Error}>}
    */
  async getAll(page = 1, perPage = 15, status = 'ACTIVE') {
    const { data, err } = await this.api.getAll(page, perPage, status);
    if (err && err == 'record not found') {
      return { data: null, err: null };
    }
    return { data, err };
  }
}

export default new ProductUseCase();
