import ProductDetailRepository from '../api/productDetail.js';
import '../types';

class ProductDetailUseCase {
  constructor() {
    /** @type {ProductDetailRepository} */
    this.api = new ProductDetailRepository();
  }

  /**
    *
    * @param productID {number}
    * @return {Promise<{data: ProductDetail, err: Error}>}
    */
  async getDetail(productID) {
    const { data, err } = await this.api.getDetail(productID);
    if (err && err == 'record not found') {
      return { data: null, err: null };
    }
    return { data, err };
  }
}

export default new ProductDetailUseCase();
